<template>
    <settings-wrapper>
        <template v-slot:child>
            <change-card-modal @on-successful-payment="onSuccessfulPayment" @on-cancelled-payment="onCancelledPayment" />
            <div class="w-90 center">
                <section class="margin24 pad1624 billBorder">
                    <div class="flex justify-between">
                        <div>
                            <div class="b font20">{{ plan?.businessPlan || 'Free' }}</div>
                            <div class="pt1">Ideal for new new businesses, online ventures, and side hustles.</div>
                        </div>
                        <div class="b font20">{{ formatAmount(plan?.amount, $store.state.Settings?.currency) }}</div>
                    </div>
                    <router-link :to="{ name: 'Plan' }"><button class="mar16">Update</button></router-link>
                </section>
                <section class="margin24">
                    <div class="flex mbFlexx justify-between">
                        <div class="w-40 billBorder mbWidthh">
                            <div class="flex justify-between pad16">
                                <div>
                                    <div class="b">Payment Method</div>
                                    <div class="pv3">
                                        <div class="b infoColor">Card Information</div>
                                        <div class="pt1 b">
                                            <span v-if="plan?.authorization?.card_type">
                                                {{ plan?.authorization?.card_type || '' }} {{ plan?.authorization?.channel || '' }} ending in {{ plan?.authorization?.last4 || '' }}
                                            </span>
                                            <span v-else>-</span>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="b infoColor">Name on Card</div>
                                        <div class="pt1 b">{{ plan?.authorization?.account_name || '' }}</div>
                                    </div>
                                </div>
                                <div>
                                    <button @click="showChangeCardModal(true)">Update</button>
                                </div>
                            </div>
                        </div>
                        <div class="w-40 billBorder mbWidthh">
                            <div class="flex justify-between pad16">
                                <div class="w-100" style="border: 1px soild black">
                                    <div class="b">Billing Date</div>
                                    <div class="pv3">
                                        <div class="b infoColor">Next Billing Date</div>
                                        <div class="pt1 b">{{ state.nextPaymentDate }}</div>
                                    </div>
                                    <div>
                                        <div class="b infoColor">Billing Address</div>
                                        <div class="pt1 b w-100 flex gap8 items-center">
                                            <span class="w-100"><input v-model="state.billingAddress" class="w-100 billAdr" /></span>
                                            <span><button @click="updateBilling">Update</button></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="margin24">
                    <div class="overflow-auto">
                        <table class="f6 w-100" cellspacing="0">
                            <thead>
                                <tr>
                                    <th class="py-8 billPad manageHeader pl2 fw6 tl p9 pr3">Invoice No</th>
                                    <th class="py-8 billPad manageHeader pl3 fw6 tl p9 pr3">Description</th>
                                    <th class="py-8 billPad manageHeader pl3 fw6 tl p9 pr3">Card</th>
                                    <th class="py-8 billPad manageHeader pl3 fw6 tl p9 pr3">Amount</th>
                                </tr>
                            </thead>
                            <tbody class="lh-copy">
                                <tr v-for="(history) in paymentHistory?.data" v-bind:key="history">
                                    <td class="pv2 pl2">{{ history?.payment_reference }}</td>
                                    <td class="pv2 pl3">{{ history?.description }}</td>
                                    <td class="pv2 pl3">
                                        <span v-if="history?.authorization?.card_type">
                                            {{ history?.authorization?.card_type || '' }} {{ history?.authorization?.channel || '' }} ending in {{ history?.authorization?.last4 || '' }}
                                        </span>
                                        <span v-else>-</span>
                                    </td>
                                    <td class="pv2 pl3">{{ formatAmount(history.amount, $store.state.Settings?.currency) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </template>
    </settings-wrapper>
</template>

<script>
import { computed, onMounted, reactive, watch } from 'vue'
import { useStore } from 'vuex'
import OrgData from '@/components/OrgData'
import SettingsWrapper from '@/views/Settings/widgets/SettingsWrapper'
import { formatAmount, formatDate } from '@/utils/lib'
import ChangeCardModal from './widgets/ChangeCardModal'
export default {
    name: 'BillingNew',
    components: { SettingsWrapper, OrgData, ChangeCardModal },
    setup() {
        const store = useStore()
        const orgData = computed(() => store?.state?.Auth?.userData)
        const plan = computed(() => store.state?.Settings?.paymentPlan)
        const organization = computed(() => store.state?.Settings?.organization)
        const members = computed(() => store.state.Settings.members)
        const businesses = computed(() => store?.state?.Settings?.businesses)
        const customerCount = computed(() => store?.state.Entity.customerCount)
        const paymentHistory = computed(() => store?.state?.Settings?.paymentHistory)
        const cardDetails = computed(() => store?.state?.Settings?.cardDetails)

        const initaialState = {
            activated: false,
            nextPaymentDate: '',
            lastFourDigits: '',
            billingAddress: '',
        }

        const state = reactive(initaialState)

        watch(plan, (newValue, oldValue) => {
            if (newValue !== oldValue) {
                state.activated = newValue?.status === 'success'
                const expiryDate = new Date(newValue?.expiryDate)
                state.nextPaymentDate = new Date(new Date(expiryDate).setDate(new Date(expiryDate).getDate() + 1)).toDateString()
                state.billingAddress = newValue?.billingAddress
                if (state.nextPaymentDate === 'Invalid Date') {
                    state.nextPaymentDate = 'N/A'
                }
            }
        })

        // watch cardDetails
        watch(cardDetails, (newValue, oldValue) => {
            if (newValue !== oldValue) {
                state.lastFourDigits = newValue?.data[newValue?.data.length - 1]?.data?.authorization?.last4
            }
        })


        const updateBilling = async () => {
            const updatePayload = {
                reference: plan.value.payment_reference,
                billingAddress: state.billingAddress,
                type: 'update',
            }
            await store.dispatch('Settings/verifyPayment', updatePayload)
        }

        const showChangeCardModal = (payload = true) => {
            store.dispatch('Settings/showChangeCardModal', payload)
        }
        const onSuccessfulPayment = async (response) => {
            if (response.status === 'success') {
                response.planId = plan.value._id
                store.dispatch('Settings/changeCard', response).then((data) => {
                    if (data.status) {
                        showChangeCardModal(false)
                    }
                })
            }
        }

        const onCancelledPayment = () => {
            showChangeCardModal(false)
        }

        onMounted(() => {
            store.dispatch('Settings/getOrganization')
            store.dispatch('Settings/getPlan')
            store.dispatch('Settings/getTeamMembers')
            store.dispatch('Settings/getBusinesses')
            store.dispatch('Entity/countCustomer')
            store.dispatch('Settings/getCardDetails')
            store.dispatch('Settings/getPaymentHistory')
        })

        return {
            plan,
            organization,
            orgData,
            members,
            businesses,
            customerCount,
            paymentHistory,
            cardDetails,
            formatAmount,
            formatDate,
            showChangeCardModal,
            onSuccessfulPayment,
            onCancelledPayment,
            state,
            updateBilling,
        }
    },
}
</script>
<style>
.margin24 {
    margin-top: 24px;
}
.pad1624 {
    padding: 24px 16px;
}
.mar16 {
    margin-top: 16px;
}
.pad16 {
    padding: 16px;
}
.font20 {
    font-size: 20px;
}

@media screen and (max-width: 700px) {
    .mbFlexx {
        flex-wrap: wrap;
        margin-top: 0;
    }
    .mbWidthh {
        width: 100%;
        margin-top: 24px;
    }
}
</style>
