<template>
    <!-- form modal -->
    <div
        tabindex="0"
        @keydown.esc="toggleChangeCardModal()"
        class="w-100 center modal pt0-l pt7"
        id="add-product-form"
        :class="showChangeCardModal && 'modal-target'"
        style="z-index: 999999"
        @click="handleOnClickOutsideModal"
    >
        <div class="w-30-l w-90 pv2 center box-border" style="background: white" ref="modalRef">
            <div class="box-border-bottom f3 pa3 b">Change Card</div>
            <div class="entity-form" ref="formRef">
                <div class="alert alert-info">
                    <div><strong><font-awesome-icon icon="fa-exclamation-circle" /> Card Authorization:</strong></div>
                    We will charge your account a <span class="b text-danger"><span class="naira">N</span>50</span> box for card authorization.
                </div>
                <div class="ph5">
                    <div class="flex items-center justify-between w-100">
                        <button class="btn" @click="onCancelledPayment">Cancel</button>
                        <paystack
                            buttonClass=""
                            buttonText="Change Card"
                            :publicKey="state.paystackPublicKey"
                            :amount="50 * 100"
                            :firstname="userData.fullName"
                            :email="userData.email"
                            :reference="state.reference"
                            :currency="state.currency"
                            label="Change Card"
                            :onSuccess="(response) => onSuccessfulPayment(response)"
                            :onCanel="onCancelledPayment"
                        ></paystack>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { plansName, formatAmount } from '@/utils/lib'
import paystack from 'vue3-paystack';

export default {
    name: 'ChangeCardModal',
    components: { paystack },

    setup(props, { emit }) {
        const modalRef = ref(null)
        const store = useStore()
        const userData = computed(() => store?.state?.Auth?.userData)
        const showChangeCardModal = computed(() => store?.state.Settings.showChangeCardModal)
        const selectedPlanIndex = computed(() => store?.state.Settings.selectedPlanIndex)

        const state = reactive({
            reference: '',
            currency: 'NGN',
            paystackPublicKey: `${process.env.VUE_APP_PAYSTACK_PUBLIC_KEY}`,
        })

        const toggleChangeCardModal = (payload = false) => {
            store.dispatch('Settings/showChangeCardModal', payload)
        }

        // close when click outside modal
        const handleOnClickOutsideModal = e => {
            if (modalRef.value && !modalRef.value.contains(e.target)) {
                toggleChangeCardModal()
            }
        }

        const onSuccessfulPayment = async (response) => {
            store.dispatch('Settings/generateTransactionId').then((data) => {
                state.reference = data.reference
            })
            emit('onSuccessfulPayment', response)
        }

        const onCancelledPayment = () => {
            emit('onCancelledPayment')
        }

        onMounted(() => {
            store.dispatch('Settings/generateTransactionId').then((data) => {
                state.reference = data.reference
            })
        })

        return {
            state,
            showChangeCardModal,
            toggleChangeCardModal,
            modalRef,
            handleOnClickOutsideModal,
            plansName,
            selectedPlanIndex,
            formatAmount,
            userData,
            onSuccessfulPayment,
            onCancelledPayment,
        }
    }
}
</script>

<style scoped></style>
